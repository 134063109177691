const mhEnv = 'dev';
export const environment = {
  apiBaseUrl:
    'https://api-dev.cr.nonprod.mheducation.com/clinical-reasoning-middleware',
  umeApiBaseUrl: `https://api-${mhEnv}.ume.nonprod.mheducation.com`,
  fileBaseUrl: 'assets/data',
  env: mhEnv,
  cdnUrl: `https://cdn-dev.ume.nonprod.mheducation.com`,
  featureFlags: {
    searchComponent: true,
    clinicalReasoningJumpTo: true,
  },
  cookieTtl: 1000 * 60 * 15,
  logout: () => {
    location.href = `https://${location.host}/mg/auth?redirectUrl=${encodeURIComponent(location.href)}`;
    console.warn(
      'local workaround for logout, please add the MH_TOKEN to the local storage.'
    );
  },
};
