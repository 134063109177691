import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { computed, inject } from '@angular/core';
import { catchError, Observable, of, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { withEntities } from '@ngrx/signals/entities';
import { User, UserFields, UserService } from 'src/lib/api/user.service';
import { ApiResponse, AuthenticationResponse } from '../models';

export interface AuthenticationState {
  user: User;
  loading: boolean;
  error?: any;
}

export const initialState: AuthenticationState = {
  user: {
    id: '',
    username: '',
    email: '',
    givenName: '',
    familyName: '',
    locale: '',
    timezone: '',
    profilePictureUrl: '',
  },
  loading: false,
  error: null,
};

export const AuthStore = signalStore(
  { providedIn: 'root' },
  withEntities<User>(),
  withState<AuthenticationState>({ ...initialState }),
  withComputed((store) => ({
    isLoggedIn: computed(() => !!store.user().id),
    user: computed(() => store.user()),
  })),
  withMethods((store, userService = inject(UserService)) => ({
    logout(): void {
      window.localStorage.removeItem('MH_TOKEN');
      environment.logout();
    },
    getCurrentUser(
      fields: UserFields[]
    ): Observable<ApiResponse<AuthenticationResponse>> {
      patchState(store, (state) => ({
        ...state,
        loading: true,
      }));
      return userService.getCurrentUser(fields).pipe(
        take(1),
        tap((user) => {
          const u = user.data.getUser.user;
          patchState(store, (state) => ({
            ...state,
            loading: false,
            user: u,
          }));
        }),
        catchError((error: any) => {
          patchState(store, (state) => ({
            ...state,
            loading: false,
            error,
            user: initialState.user,
          }));
          environment.logout();
          return of(error);
        })
      );
    },
  }))
);
