import { inject, Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthStore } from '../signal-store';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VerifyUserResolver implements Resolve<boolean> {
  authStore = inject(AuthStore);

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authStore
      .getCurrentUser(['familyName', 'id', 'givenName'])
      .pipe(
        map((response) => {
          if (response.ok || response.data.getUser.user) {
            return true;
          }
          this.authStore.logout();
          return false;
        }),
        catchError(() => {
          this.authStore.logout();
          return of(false);
        })
      );
  }
}
