import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingStore } from 'src/lib/signal-store';
import { environment } from 'src/environments/environment';
import { finalize, map, Observable, switchMap, tap } from 'rxjs';
import { AuthorizeService, Cookie } from 'src/lib/api/authorize.service';

type CookieStore = {
  cookies: Cookie[];
  ttl: number;
};

const suffix = ['users', 'authorize', 'config'];
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  route = inject(ActivatedRoute);
  loadingStore = inject(LoadingStore);
  cdnCookieStore = new Map<string, CookieStore>();

  exceptions = [
    {
      method: 'GET',
      url: '/clinical-reasoning-middleware',
    },
    {
      method: 'POST',
      url: '/clinical-reasoning-middleware',
    },
  ];

  constructor(private readonly authorizeService: AuthorizeService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<Event>> {
    let mhToken = window.localStorage.getItem('MH_TOKEN');
    if (!mhToken) {
      mhToken = getMHTokenFromCookie();
      window.localStorage.setItem('MH_TOKEN', mhToken);
    }
    const isExempted = this.exceptions.some(
      (e) => e.method === request.method && request.url.indexOf(e.url) > -1
    );
    if (!isExempted) {
      this.loadingStore.toggleLoading(true);
    }
    if (isCDNEndpoint(request.url)) {
      return this.getCookies(request.url).pipe(
        switchMap((cookies) => {
          //  'Key-Pair-Id'
          const keyPairId = cookies.cookies.find(
            (c) => c.key === 'CloudFront-Key-Pair-Id'
          )?.value;
          // 'Policy'
          const policy = cookies.cookies.find(
            (c) => c.key === 'CloudFront-Policy'
          )?.value;
          // 'Signature'
          const signature = cookies.cookies.find(
            (c) => c.key === 'CloudFront-Signature'
          )?.value;
          const expires = cookies.ttl;
          const params = `Key-Pair-Id=${keyPairId}&Policy=${policy}&Signature=${signature}&Expires=${expires}`;
          const cloneRequest = request.clone({
            url: `${setBaseUrl(environment, request.url)}${request.url}?${params}`,
          });
          return next.handle(cloneRequest);
        }),
        finalize(() => {
          this.loadingStore.toggleLoading(false);
        })
      );
    }
    if (request.withCredentials && mhToken) {
      return next
        .handle(
          request.clone({
            url: `${setBaseUrl(environment, request.url)}${request.url}`,
            setHeaders: {
              Authorization: `Bearer ${mhToken}`,
            },
          })
        )
        .pipe(
          map((event) => {
            if (event instanceof HttpResponse) {
              const authHeaders = event.headers.getAll('Authorization') || [];
              authHeaders.forEach((token) => {
                const newToken = token.split(' ')[1];
                if (newToken) {
                  window.localStorage.setItem('MH_TOKEN', newToken);
                }
              });
            }
            return event;
          }),
          finalize(() => {
            this.loadingStore.toggleLoading(false);
          })
        );
    } else if (isUMEEndpoint(request.url)) {
      return next
        .handle(
          request.clone({
            url: `${setBaseUrl(environment, request.url)}${request.url}`,
          })
        )
        .pipe(
          finalize(() => {
            this.loadingStore.toggleLoading(false);
          })
        );
    } else {
      return next
        .handle(
          request.clone({
            url: `${setBaseUrl(environment, request.url)}/${request.url}`,
          })
        )
        .pipe(
          finalize(() => {
            if (!isExempted) {
              this.loadingStore.toggleLoading(false);
            }
          })
        );
    }
  }

  private getCookies(url: string): Observable<CookieStore> {
    const productId = url.split('/')[1];
    const packageId = url.split('/')[2];
    const locale = url.split('/')[3];
    const version = url.split('/')[4];
    if (this.cdnCookieStore.has(packageId)) {
      const cookieStore = this.cdnCookieStore.get(packageId);
      if (cookieStore && cookieStore.ttl > Date.now()) {
        return new Observable((observer) => {
          observer.next(cookieStore);
          observer.complete();
        });
      }
    }
    return this.authorizeService
      .getCookies(packageId, productId, locale, version)
      .pipe(
        map((cookies) => {
          this.cdnCookieStore.set(packageId, {
            cookies,
            ttl: Date.now() + environment.cookieTtl,
          });
          return this.cdnCookieStore.get(packageId)!;
        })
      );
  }
}

function setBaseUrl(
  environment: {
    apiBaseUrl: string;
    umeApiBaseUrl: string;
    env: string;
    cdnUrl: string;
  },
  url: string
): string {
  if (environment.env === 'local' && !isCDNEndpoint(url)) {
    return '';
  }

  if (environment.env === 'local' && isCDNEndpoint(url)) {
    return '/cdn';
  }

  if (isCDNEndpoint(url)) {
    return environment.cdnUrl;
  }

  return suffix.some((s) => url.includes(s))
    ? environment.umeApiBaseUrl
    : environment.apiBaseUrl;
}

function isUMEEndpoint(url: string): boolean {
  return suffix.some((s) => url.includes(s));
}

function isCDNEndpoint(url: string): boolean {
  return url.toLowerCase().includes('clinical-reasoning');
}

function getMHTokenFromCookie(): string {
  const cookies = document.cookie.split(';');
  const mhToken = cookies.find((c) => c.includes('MH_TOKEN'));
  return mhToken ? mhToken.split('=')[1] : '';
}
