<div class='app-wrapper' [class.gray-bg]="applyStyle" [class.gradient]='gradient && isLoggedIn()'>
  <header class='header-wrapper' *ngIf="isLoggedIn()">
    <app-header-wrapper />
  </header>

  <main class='content-wrapper'>
    <div class='container'>
      <router-outlet></router-outlet>
      <app-modal *ngIf="isLoggedIn()" #modal size="large" headerText="Illness Script" (onClose)="this.visibleIllness = null">
        <app-single-illness *ngIf="visibleIllness" [illness]="visibleIllness" />
      </app-modal>
    </div>
  </main>
</div>
