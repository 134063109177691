import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, AuthenticationResponse } from '../models';

export type UserFields =
  | 'id'
  | 'givenName'
  | 'email'
  | 'lastName'
  | 'profile'
  | 'locale'
  | 'timezone'
  | 'profilePictureUrl'
  | 'familyName'
  | 'username';

export interface User {
  id: string;
  username: string;
  email: string;
  givenName: string;
  familyName: string;
  middleName?: string;
  locale: string;
  timezone: string;
  profilePictureUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly httpService: HttpClient) {}

  getCurrentUser(fields: UserFields[]) {
    return this.httpService.post<ApiResponse<AuthenticationResponse>>(
      '/users',
      this.buildQuery(fields),
      {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }

  private buildQuery(fields: UserFields[]): string {
    if (!fields || fields.length === 0) {
      throw new Error('At least one field must be specified.');
    }

    const allowedFields: UserFields[] = [
      'id',
      'givenName',
      'email',
      'lastName',
      'profile',
      'locale',
      'timezone',
      'profilePictureUrl',
      'familyName',
      'username',
    ];
    const invalidFields = fields.filter(
      (field) => !allowedFields.includes(field)
    );

    if (invalidFields.length > 0) {
      throw new Error(`Invalid fields: ${invalidFields.join(', ')}`);
    }

    const fieldsString = fields.join(', ');

    const query = `
      query GetUser {
        getUser {
          user {
            ${fieldsString}
          }
        }
      }
    `;

    const formattedQuery = JSON.stringify({
      query: query,
    });
    return formattedQuery;
  }
}
