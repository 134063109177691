import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Algorithm } from './algorithm';

@Injectable()
export class AlgorithmService {
  constructor(private httpClient: HttpClient) {}

  getById(manifestationId: string): Observable<Algorithm[]> {
    return this.httpClient.get<Algorithm[]>('/algorithms/' + manifestationId);
  }
}
